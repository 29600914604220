import { createSlice } from '@reduxjs/toolkit';
import { getVersion } from 'react-native-device-info';
//
import { getAppVersions } from '~store/app/thunks';

const status = {
  versions: 'idle',
};
export const { actions, reducer } = createSlice({
  name: 'app',
  initialState: {
    versions: [],
    version: getVersion(),
    status,
  },
  reducers: {
    setStatus: (state, { payload: { key, value } }) => {
      state.status[key] = value;
    },
    resetStatus: (state) => {
      state.status = status;
    },
  },
  extraReducers: {
    [getAppVersions.pending]: (state) => {
      state.status.versions = 'pending';
    },
    [getAppVersions.fulfilled]: (state, { payload: { data } }) => {
      if (data.success) {
        state.versions = data.data;
      }
      state.status.versions = 'succeeded';
    },
  },
});

export default reducer;

import { createAsyncThunk } from '@reduxjs/toolkit';
import search from '~api/search';
import lulla from '~api/lulla';

export const getSearchSuggestions = createAsyncThunk(
  'babyRegistry/getSearchSuggestions',
  async ({ keyword, types, limit }) =>
    await search.getSearchSuggestions({ keyword, types, limit }),
);

export const getBabyRegistry = createAsyncThunk(
  'babyRegistry/getBabyRegistry',
  async (params) => {
    const response = await lulla.getBabyRegistry(params);
    return response.data;
  },
);

export const addToBagBabyRegistry = createAsyncThunk(
  'babyRegistry/addToBagBabyRegistry',
  async (payload) => {
    const response = await lulla.addToBagBabyRegistry(payload);
    return response.data;
  },
);

export const updateBabyRegistry = createAsyncThunk(
  'babyRegistry/updateBabyRegistry',
  async ({ payload, id }) => {
    const response = await lulla.updateBabyRegistry({ payload, id });
    return response.data;
  },
);

export const deleteBabyRegistry = createAsyncThunk(
  'babyRegistry/deleteBabyRegistry',
  async (id) => {
    const response = await lulla.deleteBabyRegistry(id);
    return response.data;
  },
);

export const getTotalBabyRegistry = createAsyncThunk(
  'babyRegistry/getTotalBabyRegistry',
  async () => {
    const response = await lulla.getTotalBabyRegistry();
    return response.data;
  },
);

export const deleteProductBabyRegistry = createAsyncThunk(
  'babyRegistry/deleteProductBabyRegistry',
  async (payload) => {
    const response = await lulla.deleteProductBabyRegistry(payload);
    return response.data;
  },
);

export const getBabyRegistryCount = createAsyncThunk(
  'babyRegistry/getBabyRegistryCount',
  async (params) => {
    const response = await lulla.getBabyRegistryCount(params);
    return response.data;
  },
);

export const loadMoreBabyRegistry = createAsyncThunk(
  'babyRegistry/loadMoreBabyRegistry',
  async (params) => {
    const response = await lulla.getBabyRegistry(params);
    return response.data;
  },
);

export const getNotifBabyRegistry = createAsyncThunk(
  'babyRegistry/getNotifBabyRegistry',
  async (params) => {
    const response = await lulla.getBabyRegistry(params);
    return response.data;
  },
);

import { createSlice } from '@reduxjs/toolkit';
import {
  getArticleCategories,
  getPostHighlightArticles,
  getPostArticles,
  getDetailArticles,
  getCommentsArticle,
  loadMorePostArticles,
  loadMoreDetailArticles,
  getTotalPostArticle,
  loadMoreCommentsArticle,
} from '~store/articles/thunks';

export const { reducer } = createSlice({
  name: 'article',
  initialState: {
    status: {
      articles: 'idle',
      postHighlightArticles: 'idle',
      postArticles: 'idle',
      detailArticles: 'idle',
      commentsArticle: 'idle',
      loadMorePostArticles: 'idle',
      loadMoreDetailArticles: 'idle',
      totalPostArticle: 'idle',
      loadMoreComments: 'idle',
    },

    articles: [],
    postHighlightArticles: [],
    postArticles: [],
    detailArticles: [],
    commentsArticle: [],
    totalPostArticle: null,
    paramsReplyComment: null,
  },
  reducers: {
    setState(state, { name, value }) {
      state[name] = value;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getArticleCategories.pending, (state) => {
        state.status.articles = 'loading';
      })
      .addCase(getArticleCategories.rejected, (state) => {
        state.status.articles = 'failed';
      })
      .addCase(getArticleCategories.fulfilled, (state, { payload }) => {
        state.articles = payload.data;
        state.status.articles = 'succeeded';
      })
      .addCase(getPostHighlightArticles.pending, (state) => {
        state.status.postHighlightArticles = 'loading';
      })
      .addCase(getPostHighlightArticles.rejected, (state) => {
        state.status.postHighlightArticles = 'failed';
      })
      .addCase(getPostHighlightArticles.fulfilled, (state, { payload }) => {
        state.postHighlightArticles = payload.data;
        state.status.postHighlightArticles = 'succeeded';
      })
      .addCase(getPostArticles.pending, (state) => {
        state.status.postArticles = 'loading';
      })
      .addCase(getPostArticles.rejected, (state) => {
        state.status.postArticles = 'failed';
      })
      .addCase(getPostArticles.fulfilled, (state, { payload }) => {
        state.postArticles = payload.data;
        state.status.postArticles = 'succeeded';
      })
      .addCase(getDetailArticles.pending, (state) => {
        state.status.detailArticles = 'loading';
      })
      .addCase(getDetailArticles.rejected, (state) => {
        state.status.detailArticles = 'failed';
      })
      .addCase(getDetailArticles.fulfilled, (state, { payload }) => {
        state.detailArticles = payload.data;
        state.status.detailArticles = 'succeeded';
      })

      .addCase(loadMoreDetailArticles.pending, (state) => {
        state.status.loadMoreDetailArticles = 'loading';
      })
      .addCase(loadMoreDetailArticles.rejected, (state) => {
        state.status.loadMoreDetailArticles = 'failed';
      })
      .addCase(loadMoreDetailArticles.fulfilled, (state, { payload }) => {
        state.detailArticles = payload.data;
        state.status.loadMoreDetailArticles = 'succeeded';
      })

      .addCase(getCommentsArticle.pending, (state) => {
        state.status.commentsArticle = 'loading';
      })
      .addCase(getCommentsArticle.rejected, (state) => {
        state.status.commentsArticle = 'failed';
      })
      .addCase(getCommentsArticle.fulfilled, (state, { payload }) => {
        state.commentsArticle = payload.data;
        state.status.commentsArticle = 'succeeded';
      })
      .addCase(loadMorePostArticles.pending, (state) => {
        state.status.loadMorePostArticles = 'loading';
      })
      .addCase(loadMorePostArticles.rejected, (state) => {
        state.status.loadMorePostArticles = 'failed';
      })
      .addCase(loadMorePostArticles.fulfilled, (state, { payload }) => {
        state.postHighlightArticles = state.postHighlightArticles.concat(
          payload.data,
        );

        state.status.loadMorePostArticles = 'succeeded';
      })
      .addCase(getTotalPostArticle.pending, (state) => {
        state.status.totalPostArticle = 'loading';
      })
      .addCase(getTotalPostArticle.rejected, (state) => {
        state.status.totalPostArticle = 'failed';
      })
      .addCase(getTotalPostArticle.fulfilled, (state, { payload }) => {
        state.totalPostArticle = payload.count;
        state.status.totalPostArticle = 'succeeded';
      })

      .addCase(loadMoreCommentsArticle.rejected, (state) => {
        state.status.loadMoreComments = 'error';
      })
      .addCase(loadMoreCommentsArticle.pending, (state) => {
        state.status.loadMoreComments = 'pending';
      })
      .addCase(loadMoreCommentsArticle.fulfilled, (state, { payload }) => {
        const data = payload.data || [];
        data.map((review, key) => {
          payload.data[key].indexArray = key;
        });
        state.commentsArticle = [...state.commentsArticle, ...payload.data];
        state.status.loadMoreComments = 'succeeded';
      });
  },
});

export default reducer;

import { createSlice } from '@reduxjs/toolkit';
import {
  getSearchSuggestions,
  getBabyRegistry,
  addToBagBabyRegistry,
  deleteProductBabyRegistry,
  updateBabyRegistry,
  getBabyRegistryCount,
  loadMoreBabyRegistry,
  getNotifBabyRegistry,
} from '~store/baby-registry/thunks';

export const { actions, reducer } = createSlice({
  name: 'babyRegistry',
  initialState: {
    suggestionsProducts: [],
    statusSearchSuggestions: 'idle',
    statusAddToBag: 'idle',
    statusRemoveProduct: 'idle',
    statusUpdateRegistry: 'idle',
    statusData: 'idle',
    statusDataCount: 'idle',
    statusLoadMore: 'idle',
    statusNotifData: 'idle',
    data: {},
    dataCount: {},
    selectDeliveryType: '',
    registryChanges: false,
    isEditProfile: false,
    isAllowedAccessToRegistry: true,
    notifData: null,
    isUpdateDeliveryAddress: false,
  },
  reducers: {
    setIsUpadateDeliveryAddress: (state, { data }) => {
      state.isUpdateDeliveryAddress = data;
    },
    setIsAllowedAccessToRegistry: (state, { data }) => {
      state.isAllowedAccessToRegistry = data;
    },
    setRegistryChanges: (state, { data }) => {
      state.registryChanges = data;
    },
    setRegistry: (state, { data }) => {
      state.data = data;
    },
    setRegistryTitle: (state, { data }) => {
      state.data.title = data;
    },
    setRegistryDueDate: (state, { data }) => {
      state.data.due_date = data;
    },
    setSelectDeliveryType: (state, { data }) => {
      state.selectDeliveryType = data;
    },
    setEditProfile: (state, { data }) => {
      state.isEditProfile = data;
    },
    setSuggestionsProducts: (state, { data }) => {
      state.suggestionsProducts = data;
    },
    setStatusSearchSuggestionsProducts: (state, { data }) => {
      state.statusSearchSuggestions = data;
    },
    mergeProducts: (state, { product }) => {
      if (state.data.detail && Array.isArray(state.data.detail)) {
        state.data.detail.push(product);
      } else {
        state.data.detail = [product];
      }
    },
    updateProduct: (state, { product }) => {
      const isDetailsUpdated = false;
      if (state.data.detail && Array.isArray(state.data.detail)) {
        state.data.detail.forEach((productRegistry, key) => {
          if (productRegistry.combination_id === product.combination_id) {
            state.data.detail[key] = product;
            return false;
          }
        });

        if (!isDetailsUpdated) {
          state.data.detail.unshift(product);
        }
      } else {
        // case if details on empty state
        state.data.detail = [product];
      }
    },
    updateCountProduct: (state, { data }) => {
      state.data.detail.forEach((product, index) => {
        if (product.combination_id === data.combination_id) {
          state.data.detail[index].qty = data.qty;
        }
      });
    },
    removeProduct: (state, { data }) => {
      let indexForDelete;
      state.data.detail.forEach((product, index) => {
        if (product.combination_id === data.combination_id) {
          indexForDelete = index;
        }
      });
      state.data.detail.splice(indexForDelete, 1);
    },
    setRegistryDeliveryAddress: (state, { data }) => {
      state.data.delivery_address = data;
    },
    incrementTotalProducts: (state) => {
      state.dataCount.babyRegistryDetails++;
    },
    decrementTotalProducts: (state) => {
      state.dataCount.babyRegistryDetails--;
    },
    resetBabyRegistryState: (state) => {
      state.data = {};
      state.dataCount = {};
      state.selectDeliveryType = '';
      state.registryChanges = false;
      state.isEditProfile = false;
      state.statusDataCount = 'idle';
      state.statusData = 'idle';
    },
  },
  extraReducers: {
    [getSearchSuggestions.pending]: (state) => {
      state.statusSearchSuggestions = 'pending';
    },
    [getSearchSuggestions.fulfilled]: (state, { payload }) => {
      state.suggestionsProducts = payload['add-review'] || [];
      if (payload['add-review'] && payload['add-review'].length) {
        state.statusSearchSuggestions = 'succeeded';
      } else {
        state.statusSearchSuggestions = 'empty';
      }
    },
    [addToBagBabyRegistry.pending]: (state) => {
      state.statusAddToBag = 'pending';
    },
    [addToBagBabyRegistry.fulfilled]: (state) => {
      state.statusAddToBag = 'succeeded';
    },
    [getBabyRegistry.pending]: (state) => {
      state.statusData = 'pending';
    },
    [getBabyRegistry.fulfilled]: (state, { payload }) => {
      state.statusData = 'succeeded';
      state.data = payload.data;
    },
    [deleteProductBabyRegistry.pending]: (state) => {
      state.statusRemoveProduct = 'pending';
    },
    [deleteProductBabyRegistry.fulfilled]: (state) => {
      state.statusRemoveProduct = 'succeeded';
    },
    [updateBabyRegistry.pending]: (state) => {
      state.statusUpdateRegistry = 'pending';
    },
    [updateBabyRegistry.fulfilled]: (state) => {
      state.statusUpdateRegistry = 'succeeded';
    },
    [getBabyRegistryCount.pending]: (state) => {
      state.statusDataCount = 'pending';
    },
    [getBabyRegistryCount.fulfilled]: (state, { payload }) => {
      state.statusDataCount = 'succeeded';
      state.dataCount = payload.data;
    },
    [loadMoreBabyRegistry.pending]: (state) => {
      state.statusLoadMore = 'loading';
    },
    [loadMoreBabyRegistry.fulfilled]: (state, { payload }) => {
      state.statusLoadMore = 'succeeded';
    },

    [loadMoreBabyRegistry.rejected]: (state, { payload }) => {
      state.statusLoadMore = 'failed';
    },

    [getNotifBabyRegistry.pending]: (state) => {
      state.statusNotifData = 'pending';
    },
    [getNotifBabyRegistry.fulfilled]: (state, { payload }) => {
      state.statusNotifData = 'succeeded';
      state.notifData = payload.data;
    },
  },
});

export default reducer;
